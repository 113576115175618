<template lang="pug">
include ../../../configs/template
div.w-100.pa-0.text-left
  div.w-100.pa-0
    +select('rejectionReason','filteredRejectionReasonsList','rejectionReason','"name_ukr"')
  div.w-100.pa-0.mt-1
    +textarea('rejectionReasonComment','setComment')

</template>

<script >
import { mapState } from 'vuex'
import { checkAccess } from '@/mixins/permissions'
import { getDirectoryObject } from '@/mixins/main'
import { sailor } from '@/configs/endpoints'

export default {
  name: 'VerificationRefusal',
  props: {
    type: String
  },
  data () {
    return {
      rejectionReason: null,
      rejectionReasonComment: '',
      getDirectoryObject,
      checkAccess
    }
  },
  computed: {
    ...mapState({
      labelName: state => (state.main.lang === 'en') ? 'name_eng' : 'name_ukr',
      rejectionReasonsList: state => state.directory.rejectionReasons
    }),
    verificationRefusalReason () {
      if (this.$parent.$parent.$parent.comments) {
        return this.$parent.$parent.$parent.comments.find(item => item.additional_info.cancel_reason)
      } else return this.$parent.$parent.comments.find(item => item.additional_info.cancel_reason)
    },
    id () {
      return this.$route.params.id
    },
    documentID () {
      return this.$route.params.documentID
    },
    lineID () {
      return this.$route.params.lineID
    },
    filteredRejectionReasonsList () {
      if (['experience', 'serviceRecordBookLine'].includes(this.type)) return this.rejectionReasonsList
      else return this.rejectionReasonsList.filter(item => item.model_name !== 'lineinservicerecord')
    }
  },
  mounted () {
    if (this.verificationRefusalReason) {
      this.rejectionReason = getDirectoryObject({ id: this.verificationRefusalReason.additional_info.reason, value: 'rejectionReasons' })
      this.rejectionReasonComment = this.verificationRefusalReason.comment
    } else this.rejectionReason = null
  },
  methods: {
    setVerificationRefusal () {
      let url, method
      const body = {
        comment: this.rejectionReasonComment,
        additional_info: { cancel_reason: true, reason: this.rejectionReason },
        is_public: true
      }
      !this.rejectionReason && (delete body.additional_info)
      if (this.verificationRefusalReason) {
        method = 'patch'
        this.verificationRefusalReason && (body.comment_id = this.verificationRefusalReason?.id)
      } else method = 'post'

      switch (this.type) {
        case 'sailorMedical':
          url = `api/v2/sailor/${this.id}/medical/${this.documentID}/comment/`
          break
        case 'sailorPassport':
          url = `api/v3/sailor/${this.id}/sailor_passport/${this.documentID}/comment/`
          break
        case 'education':
          url = `api/v2/sailor/${this.id}/education/${this.documentID}/comment/`
          break
        case 'qualification':
          url = `api/v2/sailor/${this.id}/qualification/${this.documentID}/comment/`
          break
        case 'diplomaProof':
          url = `api/v2/sailor/${this.id}/proof_diploma/${this.documentID}/comment/`
          break
        case 'serviceRecordBook':
          url = `api/v2/sailor/${this.id}/service_record/${this.documentID}/comment/`
          break
        case 'serviceRecordBookStatement':
          url = `api/v2/sailor/${this.id}/statement/service_record/${this.documentID}/comment/`
          break
        case 'experience':
          url = `api/v2/sailor/${this.id}/experience_certificate/${this.documentID}/comment/`
          break
        case 'serviceRecordBookLine':
          url = `api/v2/sailor/${this.id}/service_record/${this.documentID}/line/${this.lineID}/comment/`
          break
        case 'sailorSQCStatement':
          url = `api/v2/sailor/${this.id}/statement/protocol_sqc/${this.documentID}/comment/`
          break
        case 'internationalPassport':
          url = `api/v2/sailor/${this.id}/foreign_passport/${this.documentID}/comment/`
          break
        case 'civilPassport':
          url = `api/v2/sailor/${this.id}/citizen_passport/${this.documentID}/comment/`
          break
        case 'verificationDocumentPhotos':
          url = `api/v1/reports/existing_document/${this.documentID}/comment/`
          break
        case 'qualificationStatement':
          url = `api/v2/sailor/${this.id}/statement/qualification/${this.documentID}/comment/`
          break
        case 'sailorPassportStatement':
          url = sailor.sailorPassportStatementComment(this.id, this.documentID)
          break
        case 'statementISC':
          url = sailor.statementISCByIdComment(this.id)
          break
      }
      this.$api[method](url, { body }).then(response => {
        if (response.code === 200) {
          this.$notification.success('commentWasAdded')
          // Update document comments array
          if (this.verificationRefusalReason) {
            if (this.$parent.$parent.$parent.comments) this.$parent.$parent.$parent.comments.filter(item => item.id !== this.verificationRefusalReason.id)
            else this.$parent.$parent.comments = this.$parent.$parent.comments.filter(item => item.id !== this.verificationRefusalReason.id)
          }
          if (this.$parent.$parent.$parent.comments) this.$parent.$parent.$parent.comments.push(response.data)
          else this.$parent.$parent.comments.push(response.data)
        } else this.$notification.error('notify.title.error')
      })
    }
  }
}

</script>
