<template lang="pug">
include ../../../configs/template.pug
div.row.w-100
  div(v-if="checkAccess('serviceRecordBook', 'preVerification', sailorDocument) || checkAccess('serviceRecordBook', 'editStatus', sailorDocument)").col-12
    +select('body.status_document', 'listStatuses', 'status', 'nameLang')
  div(v-if="checkAccess('sailorMedical', 'preVerification', sailorDocument) && statuses.includes(body.status_document)").col-12
    Reject(:statusDocument="body.status_document")
  div.col-12.mt-3
    v-btn(
      v-if="!statuses.includes(body.status_document)"
      @click="checkAccess('serviceRecordBook', 'preVerification', sailorDocument) || checkAccess('serviceRecordBook', 'editStatus', sailorDocument) ? saveNewStatus() : checkSave()"
      :loading="isLoading"
      color="success") {{ checkAccess('serviceRecordBook', 'preVerification', sailorDocument) || checkAccess('serviceRecordBook', 'editStatus', sailorDocument) ? $t('save') : $t('setVerify') }}
</template>

<script>
import { checkAccess } from '@/mixins/permissions'
import { mapState, mapGetters, mapActions } from 'vuex'
import VerificationRefusal from '@/components/atoms/VerificationRefusal/VerificationRefusal.vue'
import { STATUSES_DOCUMENT_CONSTANTS, SUCCESS_CODE } from '@/configs/constants'

export default {
  props: {
    sailorDocument: { type: Object, default: () => ({}) }
  },
  components: {
    VerificationRefusal,
    Reject: () => import('./Reject.vue')
  },
  data () {
    return {
      statuses: [
        STATUSES_DOCUMENT_CONSTANTS.SAILOR.EXPERIENCE.SERVICE_RECORD_BOOK.REJECTED,
        STATUSES_DOCUMENT_CONSTANTS.SAILOR.EXPERIENCE.SERVICE_RECORD_BOOK.IN_PROCESSING
      ],
      body: {
        status_document: this.sailorDocument.status_document
      },
      isLoading: false,
      checkAccess
    }
  },
  computed: {
    ...mapGetters(['statusChoose', 'getDirectoryObject']),
    ...mapState({
      id: state => state.sailor.sailorId,
      nameLang: state => state.main.lang === 'en' ? 'name_eng' : 'name_ukr'
    }),
    listStatuses () {
      let statuses = []
      if (checkAccess('admin')) statuses = this.statusChoose('BackOffice').concat(this.statusChoose('ServiceRecord'))
      else statuses = this.statusChoose('ServiceRecord')
      statuses.push(this.getDirectoryObject({ value: 'statuses', id: 20 }))
      return statuses
    }
  },
  methods: {
    ...mapActions(['updateRecordBookById']),

    /** Verification confirmation */
    checkSave () {
      this.$swal({
        title: this.$t('warning'),
        text: this.$t('continueVerification'),
        icon: 'info',
        buttons: [this.$t('cancel'), this.$t('setVerify')],
        dangerMode: true
      }).then((confirmation) => {
        if (confirmation) this.saveNewStatus()
      })
    },

    /** Change recordBook status */
    async saveNewStatus () {
      this.isLoading = true

      if (!checkAccess('serviceRecordBook', 'preVerification', this.sailorDocument) ||
        !checkAccess('verifierBackOfficeSecond')) {
        this.body.status_document = STATUSES_DOCUMENT_CONSTANTS.SAILOR.EXPERIENCE.SERVICE_RECORD_BOOK.VERIFICATION
      }

      const response = await this.updateRecordBookById({ ...this.$route.params, body: this.body })
      if (SUCCESS_CODE.includes(response?.code)) this.$notification.success('editInfoRecordBook')
      this.isLoading = false
    }
  }
}
</script>
